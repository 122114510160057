/* You can add global styles to this file, and also import other style files */
@import "bootstrap/dist/css/bootstrap.css";
@import "firebaseui/dist/firebaseui.css";
@import "@angular/material/theming";

html,
body {
  height: 100%;
}

.mat-toolbar {
  height: 60px !important;
}

.title-center {
  margin: 0 auto;
}

html,
body {
  height: 100%;
  margin: 0;
  background-color: white;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.main-container {
  display: flex;
  flex-direction: column;
}

.app-container {
  min-height: calc(100vh - 160px) !important;
  flex-shrink: 1;
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
}

.header {
  flex-shrink: 0;
}

.spacer {
  flex: 1 1 auto;
}



.footer {
  flex-shrink: 0;
  height: 100px !important;
  color: white !important;
  background-color: #673ab7;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  width: 100%;
}


body>.skiptranslate>iframe.skiptranslate {
  display: none !important;
  visibility: hidden !important;
}

.skiptranslate {
  iframe {
    display: none !important;
  }

  .goog-te-gadget {
    display: block !important;
  }
}

.goog-tooltip {
  display: none !important;
}

.goog-tooltip:hover {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.language-translate {
  padding: 10px;
  background-color: #ff98008f;
}

.mat-icon-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}